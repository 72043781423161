import {SectionTitle} from "../SectionTitle/SectionTitle";
import styles from "./VideoGallery.module.css"
import {useEffect, useState} from "react";
import {Carousel, FloatButton} from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';

export function VideoGallery() {
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch(`/videos/galleryVideos.json?timestamp=${new Date().getTime()}`)
      .then(response => response.json())
      .then(data => setVideos(() => data))
      .catch(error => console.error('Error fetching the videos:', error));
  }, []);

  return (
    <section className={styles.container}>
      <SectionTitle>VideOs</SectionTitle>
      <CarouselComponent videos={videos} setCurrentVideo={setCurrentVideo}></CarouselComponent>

      {currentVideo && (
        <VideoModal
          video={currentVideo}
          onClose={() => setCurrentVideo(() => null)}/>
      )}
    </section>
  )
}

const CarouselComponent = ({ videos, setCurrentVideo }) => {
  return (
    <Carousel arrows dots={styles.carouselDot}>
      {videos.map((video, i) =>
        <div key={i}>
          <div className={styles.carouselItem}>

            <Video video={video} onClick={setCurrentVideo} />
          </div>
        </div>
      )}
    </Carousel>
  );
};

function Video({video, onClick}) {
  return (
    <div className={styles.videoCard} onClick={() => onClick(video)}>
      <img className={styles.videoCardImage} src={video.thumbnail} alt="Video thumbnail" />
      <div className={styles.playCircleContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className={styles.playCircle}>
          <circle cx="12" cy="12" r="10"></circle>
          <polygon points="10 8 16 12 10 16 10 8"></polygon>
        </svg>
      </div>
      <div className={styles.videoCardOverlay}>
        <div className={styles.cardHeader}>
          <svg className={styles.cardArc} xmlns="http://www.w3.org/2000/svg">
            <path></path>
          </svg>
          <h3 className={styles.cardTitle}>{video.title}</h3>
        </div>
        <p className={styles.cardDescription}>{video.description}</p>
      </div>
    </div>
  )
}

function VideoModal({video, onClose}) {
  const [canPlay, setCanPlay] = useState(video.embedded);

  return (
    <div className={`${styles.videoPopup} ${canPlay ? styles.videoPopupVisible : ''}`} onClick={onClose}>
      <FloatButton icon={<CloseOutlined />} onClick={() => console.log('onClick')} />
      {video.embedded ? (
        <iframe width="1280" height="720" src={video.src}
                title={video.title} frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"></iframe>
      ) : (
        <video controls autoPlay playsInline muted onCanPlay={() => setCanPlay(true)}
               onClick={(e) => e.stopPropagation()}>
          <source src={video.src} type="video/mp4"/>
        </video>
      )}
    </div>
  )
}